<template>
  <ys-detail :split="0.6" :icon="detailIcon" :loading="loading" :name="detail.categoryName+' / '+detail.dictName+' / '+detail.itemName">
    <Tag size="medium" color="blue" slot="status">{{ detail.riskGrade|getBaseDataName('风险等级') }}</Tag>
    <template slot="brief">
      <ys-cell title="发现时间" :label="detail.serveTime|formatDate"/>
      <ys-cell title="提交专家" :label="detail.expertName"/>
      <ys-cell title="提交时间" :label="detail.createdAt"/>
    </template>
    <template slot="btn">

    </template>
    <template slot="main-left">
      <h1 class="h1Title">详细信息</h1>
      <div :style="{maxHeight:detailHeight+'px'}" style="overflow-y: auto">
        <ys-cell-group :gutter="2">
          <ys-cell title="项目名称" :label="detail.projectName"/>
          <ys-cell title="保单号" :label="detail.policyNumber"/>
          <ys-cell title="所选风险项目" :label="detail.categoryName+' / '+detail.dictName+' / '+detail.itemName"/>
          <ys-cell title="风险等级" :label="detail.riskGrade|getBaseDataName('风险等级')"/>
          <ys-cell title="发现时间" :label="detail.serveTime|formatDate"/>
          <ys-cell title="风险描述" :label="detail.remarksText" style="width: 100%" block/>
          <ys-cell title="风险依据" :label="detail.remarksRisk" style="width: 100%" block/>
          <ys-cell title="可能导致事故类型" :label="detail.accidentType" style="width: 100%" block/>
          <ys-cell title="整改期限" :label="detail.reformTerm|getBaseDataName('整改期限')"/>
          <ys-cell title="建议整改截止日" :label="detail.reformDate|formatDate"/>
          <ys-cell title="整改建议" :label="detail.remarksSuggest" style="width: 100%"/>
          <ys-cell title="提交专家" :label="detail.expertName"/>
          <ys-cell title="提交时间" :label="detail.createdAt"/>
        </ys-cell-group>
        <h1 class="h1Title">检查附件</h1>
        <div v-viewer="{url: 'data-src'}">
          <ys-image
              v-for="subItem in (detail.fileList||[])"
              :key="subItem.id"
              :src="config.baseURL + '/resource/GetFile/'+subItem.id+'?size=100'"
              :data-src="config.baseURL + '/resource/GetFile/'+subItem.id"
              :width="100" :height="100"/>
        </div>
      </div>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {spotCommentGetInfo} from "@/api/spotCheckTask";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: { },
  data() {
    return {
      amendDetail: {},
      loading: true
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      spotCommentGetInfo({id: this.mid}).then((res) => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
          this.setTagName(this.detail.iname);
        }
      }).catch(() => {
        this.loading = false;
      })
    },
  }
}
</script>

<style scoped>

</style>