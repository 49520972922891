<template>
  <div class="home">
    <ys-page-list
        keyID="10FD551E7BB53B8A0A5EBEC06F7DEC28"
        ref="table"
        :IDs="IDs"
        action="projects/List"
        :params="params"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="所属辖区" prop="region" show>
          <ys-region changeOnSelect v-model="params.region" @on-change="getRegion"></ys-region>
        </FormItem>
        <FormItem label="项目进度" prop="status">
          <Select v-model="params.status" transfer clearable placeholder="项目进度" style="width: 100px;">
            <Option v-for="(item,index) in baseData['项目进度']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="形象进度" prop="progress">
          <Select v-model="params.progress" transfer clearable placeholder="形象进度">
            <Option v-for="(item,index) in baseData['施工阶段']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="工程类型" prop="category" show>
          <Select v-model="params.category" transfer clearable placeholder="工程类型" style="width: 100px;">
            <Option v-for="(item,index) in baseData['项目类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="项目名称" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <!-- <Button type="primary" @click="add">新增项目</Button> -->
          <!-- <Button type="primary" @click="putInFlag=true">导入项目</Button> -->
        </ButtonGroup>
      </template>
      <template slot="detail">
        <Detail :multiBtn="multiBtn" :tabName="tabName" :mid="detailID"></Detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1000" title="修改项目">
      <Edit :mid="detailID" @on-ok="editSuccess"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/projects/detail.vue";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    Detail,
    Edit: () => import('./edit.vue')
  },
  data() {
    return {
      params: {
        isTop: false,
        noArea: true,
        hasAzx: '',
        category: '',
        countyCode: '',
        cityCode: '',
        status: '',
        progress: '',
        startAt: '',
        endAt: '',
        provinceCode: '',
        tag: '',
        keyword: '',
        orgId: '',
      },
      putInFlag: false,
      IDs: [],
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {minWidth: 230, title: '项目名称', tooltip: true, key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 120, title: '工程类型', tooltip: true, key: 'category', align: 'left', renderConfig: {type: 'baseData', parentName: '项目类型'}},
        // {width: 180, title: '安监号', key: 'code', align: 'left'},
        {width: 220, title: '投保机构', key: 'contractName', align: 'left'},
        {
          width: 230, title: '所属辖区', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}
        },
        {width: 220, title: '承保机构', key: 'policyOrgName', align: 'left'},
        {width: 180, title: '项目负责人', key: 'principalName', align: 'left'},
        {width: 250, title: '项目负责人联系电话', key: 'principalPhone', align: 'left'},
        {width: 180, title: '工程造价(元)', key: 'amount', align: 'left'},
        {width: 120, title: '项目进度', tooltip: true, key: 'status', align: 'left', renderConfig: {type: 'baseData', parentName: '项目进度'}},
        {minWidth: 220, title: '形象进度', key: 'progress', align: 'left', renderConfig: {type: 'baseData', parentName: '施工阶段'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              targetUrl: '/main/projects/detail/' + this.detailID,
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '编辑',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.orgId = this.userInfo.org_id
    this.getList();
  },
  methods: {
    async editSuccess(data, nextType) {
      this.IDs = nextType === 'detail' ? [data.id] : [];
      this.tabName = nextType ? '3' : '1';
      await this.getList(nextType);
    },
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    }
  }
}
</script>
